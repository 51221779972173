import { request, noTimeOutReq } from '@/utils/request.js'

//获取异常列表-分页
export function getCompanExceptionPageApi (pageIndex, pageSize, data) {
  return request({
    url: `/company/join/exception/${pageIndex}/${pageSize}/page`,
    method: 'post',
    data: data
  })
}

//获取异常明细
export function getCompanExceptionApi (exceptionHandleId) {
  return request({
    url: `/company/join/exception/${exceptionHandleId}/get`,
    method: 'post',
  })
}

//上报异常
export function reportCompanExceptionApi (data) {
  return request({
    url: `/company/join/exception/report`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data
  })
}

