<template>
  <div>
    <div>
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="exceptionReport"
        >上报异常</el-button
      >
    </div>

    <div style="margin-top: 20px">
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="上报时间" class="formItemBoxStyle">
          <div class="block">
            <el-date-picker
              v-model="queryTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              @change="queryDateChange"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="上报人" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.userName"
            placeholder="请输入上报人"
            @input="reloadCompanExceptionPage"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.process"
            placeholder="请选择状态"
            @change="reloadCompanExceptionPage"
          >
            <el-option label="全部" value></el-option>
            <el-option label="待处理" :value="false"></el-option>
            <el-option label="已处理" :value="true"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            @click="reloadCompanExceptionPage"
            >查询</el-button
          >
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>

        <el-table-column label="操作" min-width="40px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="text"
              @click="getCompanException(scope.row.platformExceptionHandleId)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-form>
    </div>

    <div>
      <div>
        <el-table
          id="tbd"
          v-loading="loading"
          stripe
          border
          :data="companExceptionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center', background: '#DFE6EC' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="gmtReport"
            label="上报时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.gmtReport | dateFormat
            }}</template>
          </el-table-column>
          <el-table-column
            prop="reportUserName"
            label="上报人"
            show-overflow-tooltip
            width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.reportUserName">{{
                scope.row.reportUserName
              }}</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="description"
            label="异常描述"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            width="180"
            prop="process"
            label="状态"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.process">已处理</span>
              <span v-else>未处理</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="处理回复" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.remark">{{ scope.row.remark }}</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="gmtProcess"
            label="处理时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.gmtProcess">{{
                scope.row.gmtProcess | dateFormat
              }}</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageParam.pageIndex"
          :page-size="pageParam.pageSize"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageParam.total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="上报异常"
      :visible.sync="dialogVisible"
      width="40%"
      @close="close(1)"
    >
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="exceptionDes"
        :rows="3"
        maxlength="60"
        show-word-limit
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button v-throttle type="primary" @click="reportCompanException"
          >保存</el-button
        >
        <el-button v-throttle @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="异常详情"
      :visible.sync="isOpen"
      width="30%"
      @close="close(2)"
    >
      <el-form
        :model="exceptionInfoResult"
        label-width="80px"
        :disabled="true"
        id="exception-info-box"
      >
        <el-form-item label="上报用户">
          <el-input
            v-model.trim="exceptionInfoResult.reportUserName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="异常编码">
          <el-input
            v-model.trim="exceptionInfoResult.code"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="处理回复">
          <el-input
            v-model.trim="exceptionInfoResult.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="异常描述">
          <el-input
            type="textarea"
            :rows="3"
            v-model.trim="exceptionInfoResult.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-throttle type="primary" :disabled="true">保存</el-button>
        <el-button v-throttle @click="isOpen = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanExceptionPageApi,
  getCompanExceptionApi,
  reportCompanExceptionApi,
} from '@/api/schoolManufacturer/exceptionReport.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'exceptionReport',
  data() {
    return {
      queryTime: '',
      queryParam: {
        gmtReportStart: '',
        gmtReportEnd: '',
        userName: '',
        process: '',
      },
      loading: false,
      companExceptionList: [],
      pageParam: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisible: false,
      exceptionDes: '',
      isOpen: false,
      exceptionInfoResult: {
        reportUserName: '',
        code: '',
        remark: '',
        description: '',
      },
    }
  },
  mounted() {
    this.getCompanExceptionPage()
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/schoolManufacturer/examManage',
        query: {},
      })
    },
    //获取异常列表
    getCompanExceptionPage() {
      this.loading = true
      getCompanExceptionPageApi(
        this.pageParam.pageIndex,
        this.pageParam.pageSize,
        this.queryParam
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.companExceptionList = []
            this.companExceptionList = res.data
            this.pageParam.total = 0
            this.pageParam.total = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    queryDateChange(value) {
      if (isEmpty(value)) {
        this.queryParam.gmtReportStart = ''
        this.queryParam.gmtReportEnd = ''
      } else {
        if (value.length > 0) {
          this.queryParam.gmtReportStart = Number(Date.parse(value[0]))
          this.queryParam.gmtReportEnd = Number(Date.parse(value[1]))
        }
      }
      this.reloadCompanExceptionPage()
    },
    reloadCompanExceptionPage() {
      this.pageParam.pageIndex = 1
      this.getCompanExceptionPage()
    },
    refresh() {
      this.queryTime = ''
      this.queryParam.gmtReportStart = ''
      this.queryParam.gmtReportEnd = ''
      this.queryParam.userName = ''
      this.queryParam.process = ''
      this.reloadCompanExceptionPage()
    },
    handleCurrentChange(val) {
      this.pageParam.pageIndex = val
      this.getCompanExceptionPage()
    },
    handleSizeChange(val) {
      this.pageParam.pageSize = val
      this.getCompanExceptionPage()
    },
    //获取异常明细
    getCompanException(val) {
      this.isOpen = true
      let fd = new FormData()
      fd.append('exceptionHandleId', val)
      getCompanExceptionApi(fd).then((res) => {
        if (res.success) {
          this.exceptionInfoResult = {}
          this.exceptionInfoResult = res.data
        } else {
          if (res.code != 500) {
            this.msgWarning(res.msg)
          }
        }
      })
    },
    exceptionReport() {
      this.dialogVisible = true
    },
    //上报异常
    reportCompanException() {
      if (!this.exceptionDes) {
        this.msgWarning('请输入上报信息')
        return
      }
      const loading = this.globalLoading('正在上报,请稍后...')
      reportCompanExceptionApi(this.exceptionDes)
        .then((res) => {
          loading.close()
          if (res.success) {
            this.msgSuccess('上报成功')
            this.getCompanExceptionPage()
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
          this.dialogVisible = false
        })
        .catch(() => {
          loading.close()
          this.dialogVisible = false
        })
    },
    close(type) {
      switch (type) {
        case 1:
          this.dialogVisible = false
          this.exceptionDes = ''
          break
        case 2:
          this.isOpen = false
          this.exceptionInfoResult = {}
          break
      }
    },
  },
}
</script>

<style>
::v-deep #exception-info-box .el-input,
#exception-info-box .el-textarea {
  width: 300px;
}
.el-tooltip__popper {
  max-width: 40%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  font-size: 13px;
}
</style>
